import './App.css';
import Footer from './Components/Footer';
import Hero from './Components/Hero';
import Imageslider from './Components/Imageslider';
import Navbar from './Components/Navbar';
import Offers from './Components/Offers';
import Plan from './Components/Plan';
import Rooms from './Components/Rooms';


function App() {
  return (
    <div>
      <Navbar/>
      <Hero/>
      <Offers/>
      <Plan/>
      <Rooms/>
      <Imageslider/>
      <Footer/>
    </div>
  );
}

export default App;
