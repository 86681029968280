import React from 'react'

const Plan = () => {
  return (
    <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>
      {/* left side */}
      <div className='grid grid-cols-2 grid-rows-6nh-[80vh]'>
        <img className='row-span-3 object-cover w-full h-full p-2' src="https://images.unsplash.com/photo-1621583628955-42fbc37bf424?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=435&q=80" alt="/" />
        <img className='row-span-2 object-cover w-full h-full p-2' src="https://img.freepik.com/premium-photo/sunbed-umbrella-maldives_255175-159.jpg" alt="/" />
        <img className='row-span-2 object-cover w-full h-full p-2' src="https://www.hdwallpapers.in/download/natur_water_sea_travel_sky_beach_turquoise_summer_island_4k_hd-2560x1440.jpg" alt="/" />
        <img className='row-span-3 object-cover w-full h-full p-2' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1tQsB3tgBQKYkdeBLCxUenSrEZlzKS_WyUzGt7mw83C_ypuzwRDHECIfZV3i4nEaKhnZRandYpIc&usqp=CAU&ec=48665701" alt="/" />
        <img className='row-span-2 object-cover w-full h-full p-2' src="https://cdn.pixabay.com/photo/2017/12/16/22/22/bora-bora-3023437_1280.jpg" alt="/" />

      </div>
      {/* right side */}
      <div className='flex flex-col h-full justify-center'>
        <h3 className='text-5xl md:text-6xl font-bold'>Plan Your Next Trip</h3>
        <p className='text-2xl py-6'>Lorem ipsum dolor sit amet consectetur adipisicing elit.Moslestias</p>
        <p className='pb-6'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi, temporibus veniam? Nostrum perspiciatis cum optio vitae? Laboriosam tenetur ducimus natus unde ipsam quod sunt earum animi rerum. Esse, maxime at.</p>
        <div>
          <button className='border-black mr-4 hover:shadow-xl'>Learn More</button>
          <button className='bg-black text-white border-black hover:shadow-xl'>Book Your Trip</button>
        </div>
      </div>
    </div>
  )
}

export default Plan