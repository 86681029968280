import React from 'react'

const Rooms = () => {
  return (
    <div className='max-w-[1400px] h-[500px] bg-blue-100 mx-auto my-20 pt-16 lg:mb-20% md:mb-[35%] px-4 grid lg:grid-cols-3 gap-3'>
        <div className='lg:top-20 relative lg:col-span-1 col-span-2'>
            <h3 className='text-2xl font-bold'>Fine Interior Rooms</h3>
            <p className='pt-4'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Voluptas, id aut delectus veniam similique quasi cupid</p>
                
        </div>
        <div className='grid grid-cols-2 col-span-2 gap-2'>
            <img className='object-cover w-full h-full' src="https://w0.peakpx.com/wallpaper/802/856/HD-wallpaper-beautiful-beach-view-villa-resort-lodge-bedroom-villa-sea-bed-beach-lagoon-room-luxury-wood-hotel-exotic-view-holiday-ocean-peace-suite-paradise-sanctuary-spa-tropical.jpg" alt="/" />
            <img className='row-span-2 object-cover w-full h-full' src="https://w0.peakpx.com/wallpaper/261/106/HD-wallpaper-gorgeous-hotel-room-in-bora-bora-beach-hotel-room-trees.jpg" alt="/" />
            <img className='object-cover w-full h-full' src="https://cdn1.goibibo.com/voy_ing/t_fs/003e12700f0c11ebba9c0242ac110002.png" alt="/" />


        </div>
    </div>
  )
}

export default Rooms